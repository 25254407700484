import { AppContext } from '../context/AppContext'
import { FormDefinition } from '../types'
import { useContext } from 'preact/hooks'

export const useAppContext = () =>
  useContext(AppContext) as {
    isEmdeddedMode: boolean
    formDefinition: FormDefinition
    premium?: boolean
  }
