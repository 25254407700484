import { FormFieldDefintion } from '../types'
import { h } from 'preact'

export const CheckboxField = ({ fieldDefintion }: FormFieldDefintion) => {

  const optionValuesLength = (index: number) =>
    fieldDefintion?.optionValues?.length || 0 > 1 ? `[${index}]` : ''

  const handleInput = (event: Event) => {
    const form = (event.target as HTMLInputElement).form
    if (!form) return

    const checkboxes = Array.from(
      form.querySelectorAll(`input[name^="fields[${fieldDefintion.indexPosition}].value"]`)
    ) as HTMLInputElement[]

    const isAnyChecked = checkboxes.some((checkbox) => checkbox.checked)

    checkboxes.forEach((checkbox) => {
      checkbox.required = fieldDefintion.isRequired ? !isAnyChecked : false
    })
  }

  return (
    <div class="md-embedded-form--checkbox-wrapper">
      {fieldDefintion.label && (
        <div class="md-embedded-form--input-title">{fieldDefintion.label}</div>
      )}

      {fieldDefintion?.optionValues?.map((item, index) => (
        <label class="md-embedded-form--checkbox" key={item}>
          <input
            value={item}
            name={`fields[${
              fieldDefintion.indexPosition
            }].value${optionValuesLength(index)}`}
            class="md-embedded-form--checkbox-input"
            tabIndex={fieldDefintion.indexPosition}
            type="checkbox"
            required={fieldDefintion?.isRequired}
            onInput={handleInput}
          />
          {item}
        </label>
      ))}
      <input
        value={fieldDefintion.name}
        name={`fields[${fieldDefintion.indexPosition}].name`}
        type="hidden"
      />
    </div>
  )
}
